<template>

  <v-dialog
      v-model="dialog"
     width="unset"
  >
    <template v-slot:activator="{ on, attrs }">
      <div  v-if="desconectado==true">
      <v-btn

          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
      >
        Acceder
      </v-btn></div>
      <div v-else-if="desconectado==false">

      <v-menu  offset-y min-width="200px">
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            dark
            elevation="5"
            fab
        >
          {{ abreviado }}
        </v-btn>
        </template>

        <v-list subheader>
          <v-subheader>Mis Ordenes</v-subheader>

          <v-list-item> <v-list-item-action>
            <router-link color="white" style="text-decoration: none;"  :to="{ name: 'ordenes'}"><v-icon>fas fa-luggage-cart</v-icon></router-link>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title><router-link color="black" style="text-decoration: none; color: black;"  :to="{ name: 'ordenes'}">Mis Ordenes</router-link></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list subheader>
          <v-subheader>Mis Datos</v-subheader>
          <v-list-item> <v-list-item-action>
            <router-link color="white" style="text-decoration: none;"  :to="{ name: 'perfil'}"><v-icon>fas fa-id-badge</v-icon></router-link>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title><router-link color="black" style="text-decoration: none; color: black;"  :to="{ name: 'perfil'}">Perfil</router-link></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      </div>
    </template>
    <v-card>



        <v-tabs
            v-model="tab"
            background-color="transparent"
            grow
        >
          <v-tab
              key="Conectarse"
          >
            Conectarse
          </v-tab>
          <v-tab
              key="Registrarse"
          >
           Registrarse
          </v-tab>

        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
              key="Conectarse"
          >
            <v-card

                flat
            >
              <v-card-text>
              <v-form>
                <v-alert
                    :value="alertloginerror"
                    color="error"
                    dark
                    dismissible
                    border="top"
                    transition="scale-transition"
                >
                {{error}}
                </v-alert>
                <v-row align="center" justify="center">
                  <v-col lg="6" md="6" cols="12">
                <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    type="email"
                    name="email"
                    label="E-mail"
                    value
                    required
                    autofocus
                ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">

                  <v-col lg="6" md="6" cols="12">
                    <v-text-field
                        id="password"
                        type="password"
                        class="form-control"
                        name="password"
                        label="Password"
                        required
                        v-model="password"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-0">
                  <v-col md="8" class="offset-md-4">
                    <v-btn @click="submit" class="primary">Login</v-btn>
                  </v-col>
                </v-row>
              </v-form>
<v-row>
  <v-col>
    <v-btn style="background: #4F86EC;" @click="signingoogle">
      <v-icon>fab fa-google</v-icon> &nbsp;Login con Google
    </v-btn>
  </v-col>
  <v-col>
    <v-btn style="background: #E0E5FA; color: black;" @click="signinapple">
      <v-icon>fab fa-apple</v-icon> &nbsp;Login con Apple
    </v-btn>
  </v-col>
</v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
              key="Registrase"
          >
            <v-card
                color="basil"
                flat
            >
              <v-card-text>
                <v-form>
                  <v-row align="center" justify="center">
                    <v-col lg="6" md="6" cols="12">
                      <v-text-field
                          v-model="form.nombre"
                          type="text"
                          name="nombre"
                          label="Nombre"
                          value
                          required
                          autofocus
                      ></v-text-field>
                    </v-col>
                    <v-col lg="6" md="6" cols="12">
                      <v-text-field
                          v-model="form.apellidoPaterno"
                          type="text"
                          name="appaterno"
                          label="Apellido Paterno"
                          value
                          required
                          autofocus
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">

                    <v-col lg="6" md="6" cols="12">
                      <v-text-field
                          v-model="form.email"
                          :rules="emailRules"
                          type="email"
                          name="email"
                          label="E-mail"
                          value
                          required
                          autofocus
                      ></v-text-field>
                    </v-col>
                    <v-col lg="6" md="6" cols="12">
                      <v-text-field
                          v-model="form.dni"
                          name="DNI"
                          label="DNI"
                          value
                          required
                          autofocus
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                <v-col lg="6" md="6" cols="12">
                      <v-text-field
                          id="password"
                          type="password"
                          class="form-control"
                          name="password"
                          label="Password"
                          required
                          v-model="password"
                      />
                    </v-col>

                    <v-col lg="6" md="6" cols="12">
                      <v-text-field
                          id="password"
                          type="password"
                          class="form-control"
                          label="Confirmar Password"
                          required
                          v-model="confirmpass"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mb-0">
                    <v-col md="8" class="offset-md-4">
                      <v-btn @click="register" class="primary">Registrarse</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn style="background: #4F86EC; color: white" @click="creategoogle">
                        <v-icon>fab fa-google</v-icon> &nbsp;Registrar con Google
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn style="background: #E0E5FA; color: black;" @click="createapple">
                        <v-icon>fab fa-apple</v-icon> &nbsp;Registrar con Apple
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-subtitle>
                Al hacer clic en Registrarte, aceptas las <router-link color="black" style="text-decoration: none; color: black;"  :to="{ name: 'terminos'}">Condiciones</router-link> y las <router-link color="black" style="text-decoration: none; color: black;"  :to="{ name: 'politicas'}">Políticas de datos</router-link>.
              </v-card-subtitle>
            </v-card>
          </v-tab-item>
        </v-tabs-items>


    </v-card>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
  </v-dialog>

</template>

<script>
import firebase from "firebase";
import axios from 'axios';
import store from "@/store";
import moment from "moment-timezone";
import {mapGetters} from "vuex";
import sexosjson from "@/assets/json/sexos.json";


export default {
  name: 'loginbox',
  props:{
    dialog: {type: Boolean, default: false}
  },
  data: () => ({
    tab: null,
    desconectado: true,
    alertloginerror:false,
    abreviado:'NN',
    form: {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      dni : "",
      foto: "",
      email: "",
      paisNac:{},
      tipoDocumento: {},
      telefono:"",
      direccion:"",
      fechaNacimiento:null,
      sexo:{},
      fotoDni:"",
      postalCode:"",
      distrito:{},
      createdAt:"",
      uidFirebase: "",
      grupoUsuario:{},
      isprofile: true,
      isfoto : false,
      isverified: false
    },
    mnegocios : null,
    tnegocios: false,
    teventos: false,
    password: "",
    confirmpass: "",
    paises: [],
    getips:'',
    error:'',
    overlay:false,
    emailRules: [
      v => !!v || 'E-mail es obligatorio',
      v => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
    ],
  }),
  mounted() {
    this.getPaises()
    if(this.usuario.id>0){
      this.desconectado = false
      this.abreviado = this.usuario.nombre.charAt(0) + this.usuario.apellidoPaterno.charAt(0)
    }
  },
  methods: {
    formatDateee(value) {
      return moment(value).locale('es-pe').format("YYYY-MM-DD hh:mm:ss")
    },
    getPaises(){
      axios.get("paises/activos")
      .then(response=>{
        this.paises = response.data
      })
    },
    register(){
      if(this.password === this.confirmpass) {
        this.overlay = true;
        firebase
            .auth()
        .createUserWithEmailAndPassword(this.form.email,this.password)
        .then((userCredential) =>{
            const user = userCredential.user
            this.registrar(user,false)
        })
      }
    },
    registrar(user, provider){
      this.form.uidFirebase = user.uid
      var bodyFormData = new FormData();
      this.form.createdAt = this.formatDateee(new Date())
      if(provider){
        user.providerData.forEach((profile) => {

          this.form.nombre = profile.displayName
          this.form.email =  profile.email
          this.form.foto =  profile.photoURL
        });
      }
      bodyFormData.append('data',JSON.stringify(this.form));
      axios.post("personas/registrar",bodyFormData)
          .then(response => {
            store.dispatch("fetchUsuario", response.data.persona);

            store.dispatch("fetchSexos", sexosjson);
            this.dialog=false;

            this.abreviado =response.data.persona.nombre.charAt(0) + response.data.persona.apellidoPaterno.charAt(0)
            this.desconectado = false;
          })
          .catch(error => {
            this.error = "Hubo un error con su Conexion";
            this.overlay = false;
            this.alertloginerror = true;
            console.log(error);
          });
    },
    createapple(){
      var provider = new firebase.auth.OAuthProvider('apple.com')
      firebase.auth().languageCode = 'es'
      provider.setCustomParameters({
        'display': 'popup'
      });
      firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) =>{
            // var credential = result.credential;

            // The signed-in user info.
            var user = result.user;

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // var accessToken = credential.accessToken;
            this.registrar(user,true)
            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            // var errorMessage = error.message;
            // The email of the user's account used.
            // var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            console.log(errorCode)
            this.error = "Hubo un error con su Conexion";
            this.overlay = false;
            this.alertloginerror = true;
            // ...
          });
    },
    creategoogle(){
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = 'es'
      provider.setCustomParameters({
        'display': 'popup'
      });
      firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) =>{
            // var credential = result.credential;

            // The signed-in user info.
            var user = result.user;

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // var accessToken = credential.accessToken;
            this.registrar(user,true)
            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            this.error = "Hubo un error con su Conexion";
            this.overlay = false;
            this.alertloginerror = true;
            // var errorMessage = error.message;
            // The email of the user's account used.
            // var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            console.log(errorCode)
            // ...
          });
    },
    signinapple(){
      var provider = new firebase.auth.OAuthProvider('apple.com')
      firebase.auth().languageCode = 'es'
      provider.setCustomParameters({
        'display': 'popup'
      });
      firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) =>{
            // var credential = result.credential;

            // The signed-in user info.
            var user = result.user;

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // var accessToken = credential.accessToken;
            this.logeo(user)
            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            // var errorMessage = error.message;
            // The email of the user's account used.
            // var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            console.log(errorCode)
            this.error = "Hubo un error con su Conexion";
            this.overlay = false;
            this.alertloginerror = true;
            // ...
          });
    },
    signingoogle(){
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = 'es'
      provider.setCustomParameters({
        'display': 'popup'
      });
      firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) =>{
            // var credential = result.credential;

            // The signed-in user info.
            var user = result.user;

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // var accessToken = credential.accessToken;
            this.logeo(user)
            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            this.error = "Hubo un error con su Conexion";
            this.overlay = false;
            this.alertloginerror = true;
            // var errorMessage = error.message;
            // The email of the user's account used.
            // var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            console.log(errorCode)
            // ...
          });
    },
    submit() {
      this.overlay = true;
      firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.password)
          .then(data => {
           this.logeo(data.user)
          })
          .catch(err => {
            if(err.code == "auth/user-not-found"){
              this.error = "No Existe este Email en nuestra base de datos";
              this.overlay = false;
              this.alertloginerror = true;
            }else{
              this.error = "Puede haber un error con el email y/o password";
              this.overlay = false;
              this.alertloginerror = true;

            }
          });
    },
    logeo(user){
      var bodyFormData = new FormData();

      bodyFormData.append('uuid', user.uid);

      axios.post("personas/loginbyuid",bodyFormData)
          .then(response => {
            store.dispatch("fetchUsuario", response.data.persona);
            store.dispatch("fetchSexos", sexosjson);

            this.desconectado = false;
            this.overlay = false;
            this.dialog=false;
            this.abreviado =  response.data.persona.nombre.charAt(0) +  response.data.persona.apellidoPaterno.charAt(0);
          })
          .catch(error => {
            this.error = "Hubo un error con su Conexion";
            this.overlay = false;
            this.alertloginerror = true;
            console.log(error);
          });
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      usuario: "usuario"
    })
  }
}
</script>