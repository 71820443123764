<template>
<v-footer
      color=" white"
      padless
    >
    <v-row
        justify="center"
        no-gutters
      >
      <v-btn

          color="black"
          text
          to="/"
          rounded
          class="my-2"
        >
         Inicio
        </v-btn>
      <v-btn

          color="black"
          text
          to="/politicas"
          rounded
          class="my-2"
      >
        Políticas de Privacidad
      </v-btn>
      <v-btn

          color="black"
          text
          to="/terminos"
          rounded
          class="my-2"
      >
        Términos y Condiciones
      </v-btn>
        <v-col
          class=" lighten-2 py-4 text-center "

          cols="12"
        >
          Copyright ©<strong>DTYF</strong> {{ new Date().getFullYear() }} —powered by <a href="https://tunay.app" target="_blank">Tunay</a>
        </v-col>
    </v-row>
</v-footer>
</template>
<script>

  export default {
    name: 'piepagina',

    data: () => ({

  }),
  methods: {
}
  }
</script>
