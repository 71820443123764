<template>
  <v-app-bar
      app
      absolute
    color="blue"
      height="80px"
  >
    <v-toolbar-title>
      <router-link :to="{ name: 'inicio'}">
        <v-img
            alt="DTYFY Logo"
            class="shrink mt-1 "
            contain
            min-width="100"
            src="@/assets/dtyfblanco.png"
            width="100"
        /></router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <loginbox :dialog="dialog" />
  </v-app-bar>
</template>
<script>
import loginbox from '../modals/loginbox';

export default {
  name: 'cabecera',
  components: {
    loginbox
  },
  props:{
    dialog: {type: Boolean, default: false}
  },
  data: () => ({
    links: [
    ]
  }),
}
</script>
